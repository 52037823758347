import React, { useState } from "react";
import JobErAsyncSelect from "components/JobErAsyncSelect";
import { asyncRicercaComuni, asyncRicercaRuoli } from "utils/utilsRicerca";
import { Collapse, Grid } from "@mui/material";
import JobErSelect from "components/JobErSelect";
import JobButton from "components/JobButton";
import { ProvinceER } from "utils/ProvinceER";

function SezioneFiltriAnnunci(props) {
  const [open, setOpen] = useState(true);
  ProvinceER.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Grid container mt={1} mb={2} justifyContent="center">
      <Grid item xs={12} sx={{ display: { xs: "block", md: "none" } }} mb={1}>
        <JobButton label={open ? "Nascondi Filtri" : "Visualizza Filtri"} onClick={() => setOpen(!open)} />
      </Grid>
      <Collapse in={open} timeout="auto" unmountOnExit style={{ width: "100%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4} sx={{ marginBottom: { xs: 1, md: 0 } }}>
            <JobErSelect
              mode="white"
              menuPosition="fixed"
              isClearable="true"
              isMulti={true}
              placeholder={"Ricerca la provincia"}
              onChange={(data) => {
                props.onSetFilter("provincia", data);
              }}
              value={props.filter.provincia}
              options={ProvinceER}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ marginBottom: { xs: 1, md: 0 } }}>
            <JobErAsyncSelect
              mode="white"
              menuPosition="fixed"
              isMulti={true}
              isClearable="true"
              placeholder={"Ricerca il comune"}
              value={props.filter.comuned}
              loadOptions={asyncRicercaComuni}
              noOptionsMessage={() => "Nessun comune trovato"}
              onChange={(data) => {
                props.onSetFilter("comune", data);
              }}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ marginBottom: { xs: 1, md: 0 } }}>
            <JobErAsyncSelect
              mode="white"
              menuPosition="fixed"
              isMulti={true}
              isClearable="true"
              placeholder={"Ricerca la professione"}
              value={props.filter.professione}
              loadOptions={asyncRicercaRuoli}
              noOptionsMessage={() => "Nessuna Professione trovata"}
              onChange={(data) => {
                props.onSetFilter("professione", data);
              }}
            />
          </Grid>
        </Grid>
      </Collapse>
    </Grid>
  );
}
export default SezioneFiltriAnnunci;
