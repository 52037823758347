import React, { useState } from "react";
import { decodeHTMLEntities } from "utils/utilsDati";
import { Grid, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import coloriVetrina from "json/coloriVetrina.json";
import ModalDettagliAnnuncio from "./ModalDettagliAnnuncio";

function ItemListaAnnunciEr(props) {
  const [open, setOpen] = useState(false);
  return (
    <>
      <ListItem
        alignItems="flex-start"
        key={props.annuncio.uuid}
        onClick={() => {
          setOpen(true);
        }}
      >
        <ListItemAvatar key={props.annuncio.uuid + "_candidatura"}>
          <img
            src={
              props.annuncio?.codificaMansione?.gruppo ? require("immagini/ambiti_lavoro/gr" + props.annuncio.codificaMansione.gruppo + "-260x145.jpg") : null
            }
            style={{
              height: "70px",
              width: "110px",
              borderRadius: 15,
            }}
            alt=""
          />
        </ListItemAvatar>

        <ListItemText
          key={props.annuncio.uuid + "_text"}
          sx={{ marginLeft: 2 }}
          primary={props.annuncio.titoloAnnuncio.toUpperCase()}
          primaryTypographyProps={{
            fontWeight: "bold",
            color: coloriVetrina.white,
          }}
          secondary={
            <Grid container>
              <Grid item xs={12}>
                <Typography
                  component="p"
                  sx={{
                    fontSize: "14px!important",
                    color: coloriVetrina.white,
                  }}
                >
                  {props.annuncio.comune.des && <strong> Comune di: {props.annuncio.comune.des} </strong>}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  component="p"
                  sx={{
                    fontSize: "14px!important",
                    color: coloriVetrina.white,
                  }}
                >
                  {props.annuncio.descrizioneLavoro.length > 250
                    ? decodeHTMLEntities(props.annuncio.descrizioneLavoro.substring(0, 250)) + " ..."
                    : decodeHTMLEntities(props.annuncio.descrizioneLavoro)}
                </Typography>
              </Grid>
            </Grid>
          }
        />
      </ListItem>
      <ModalDettagliAnnuncio open={open} setOpen={setOpen} titolo={props.annuncio.titoloAnnuncio} descrizioneLavoro={props.annuncio.descrizioneLavoro} />
    </>
  );
}

export default ItemListaAnnunciEr;
