import React from "react";
import JobErCard from "components/JobErCard";
import coloriVetrina from "../../json/coloriVetrina.json";
import { Divider, Grid } from "@mui/material";
import { asyncRicercaComuni, asyncRicercaRuoli } from "utils/utilsRicerca";
import JobErAsyncSelect from "components/JobErAsyncSelect";
import JobErSelect from "components/JobErSelect";
import JobErInput from "components/JobErInput";
import options from "../../json/option_configuration.json";
import JobButton from "components/JobButton";
import Swal from "sweetalert2";

function CardProfiloLavoriInteresse(props) {
  const alertDeleteLavoroInteresse = (uuid) => {
    Swal.fire({
      text: "Sei sicuro di voler cancellare questo interesse?",
      icon: "warning",
      showCancelButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        props.onRemoveNestedData(uuid);
      } else if (result.isDenied) {
      }
    });
  };

  return (
    <JobErCard
      title="Lavori di Interesse"
      style={{ minHeight: "300px" }}
      child={
        <Grid container justifyContent="center" spacing={2} mt={1}>
          {props.dati.map((interesse, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} md={6}>
                <JobErAsyncSelect
                  mode="white"
                  menuPosition="fixed"
                  isMulti={false}
                  isClearable="true"
                  placeholder={"Ruolo"}
                  loadOptions={asyncRicercaRuoli}
                  noOptionsMessage={() => "Nessun ruolo trovato"}
                  value={
                    interesse?.ruolo
                      ? {
                          value: interesse.ruolo,
                          label: interesse.ruolo,
                        }
                      : null
                  }
                  onChange={(data) => {
                    props.onSetNestedDati(interesse.uuid, "ruolo", data?.label ? data?.label : null);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <JobErAsyncSelect
                  mode="white"
                  menuPosition="fixed"
                  isMulti={false}
                  isClearable="true"
                  placeholder={"Comune"}
                  loadOptions={asyncRicercaComuni}
                  noOptionsMessage={() => "Nessun comune trovato"}
                  value={
                    interesse?.comune
                      ? {
                          value: interesse.comune,
                          label: interesse.comune,
                        }
                      : null
                  }
                  onChange={(data) => {
                    props.onSetNestedDati(interesse.uuid, "comune", data?.label ? data?.label : null);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} textAlign="left">
                <JobErSelect
                  mode="white"
                  menuPosition="fixed"
                  isClearable="true"
                  isMulti={true}
                  placeholder={"Orari Lavoro"}
                  options={options.turni_lavoro}
                  onChange={(data) => {
                    props.onSetNestedDati(interesse.uuid, "orarioLavorativo", data ? data : null);
                  }}
                  value={interesse?.orarioLavorativo ? interesse.orarioLavorativo : null}
                />
              </Grid>
              <Grid item xs={12} md={6} textAlign="left">
                <JobErSelect
                  mode="white"
                  menuPosition="fixed"
                  isClearable="true"
                  placeholder={"Tipologia contratto"}
                  options={options.tipologie_contratto}
                  onChange={(data) => {
                    props.onSetNestedDati(interesse.uuid, "tipologiaContratto", data?.label ? data?.label : null);
                  }}
                  value={
                    interesse?.tipologiaContratto
                      ? options.tipologie_contratto.filter((opt) => opt.label === interesse.tipologiaContratto || opt.value === interesse.tipologiaContratto)
                      : null
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <JobErInput
                  label="Stipendio minimo"
                  coloreTesto={coloriVetrina.black}
                  coloreBordo={coloriVetrina.gray}
                  size="small"
                  value={interesse?.stipendioMinimo ? interesse.stipendioMinimo : ""}
                  onChange={(e) => props.onSetNestedDati(interesse.uuid, "stipendioMinimo", e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <JobErInput
                  label="RAL minima"
                  coloreTesto={coloriVetrina.black}
                  coloreBordo={coloriVetrina.gray}
                  size="small"
                  value={interesse?.ralMinima ? interesse.ralMinima : ""}
                  onChange={(e) => props.onSetNestedDati(interesse.uuid, "ralMinima", e.target.value)}
                />
              </Grid>

              {index > 0 && (
                <Grid item xs={12}>
                  <JobButton
                    label="Rimuovi"
                    colorLabel={coloriVetrina.error}
                    colorBorder={coloriVetrina.error}
                    onClick={() => alertDeleteLavoroInteresse(interesse.uuid)}
                  />
                </Grid>
              )}
              <Grid item xs={12} m={1.5}>
                <Divider />
              </Grid>
            </React.Fragment>
          ))}
          <Grid item xs={12} mt={2}>
            <Grid container justifyContent="center">
              <Grid item xs="auto">
                <JobButton label="Salva" colorLabel={coloriVetrina.green} colorBorder={coloriVetrina.green} onClick={() => props.onSave()} />
                <JobButton label="Aggiungi Lavoro Interesse" onClick={() => props.onAddNestedObject()} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
}
export default CardProfiloLavoriInteresse;
