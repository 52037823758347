import { Toast } from "components/Toast";
import { getAuthHeader } from "assets/utils/auth-services";

export function setStatisticheDownloadCv(azienda) {
  if (azienda) {
    let headers = getAuthHeader();
    headers["Content-Type"] = "application/json";

    fetch(process.env.REACT_APP_URL + "/aziende/setStatisticheDownloadCv", {
      headers: headers,
      method: "POST",
      body: JSON.stringify(azienda),
    }).then((res) => {
      if (res.status !== 200) {
        Toast.fire({ icon: "error", title: "Si è verificato un errore" });
      }
    });
  } else {
    console.error("Errore nell'update download cv statistico");
  }
}

export function sbloccaCandidatoFunc(uuid, candidatiSbloccati, candidatiBloccati, setCandidatiSbloccati, setCandidatiBloccati) {
  fetch(process.env.REACT_APP_URL + "/aziende/sbloccaCandidato/" + uuid, {
    headers: getAuthHeader(),
    method: "POST",
  }).then((res) => {
    if (res.status === 200) {
      var copiaSbloccati = [...candidatiSbloccati];
      var copiaBloccati = [...candidatiBloccati];
      var candidatoDaSpostare = {};

      for (var i = 0; i < candidatiBloccati.length; i++) {
        if (candidatiBloccati[i].uuid === uuid) {
          candidatoDaSpostare = candidatiBloccati[i];
          candidatoDaSpostare.sbloccato = 1;
        }
      }

      copiaSbloccati.push(candidatoDaSpostare);
      copiaBloccati = copiaBloccati.filter((obj) => obj.uuid !== uuid);

      setCandidatiSbloccati(copiaSbloccati);
      setCandidatiBloccati(copiaBloccati);

      Toast.fire({ icon: "success", title: "Candidato sbloccato!" });
    } else {
      Toast.fire({ icon: "error", title: "Si è verificato un errore" });
    }
  });
}

export function getCandidatureAzienda(setCandidati) {
  fetch(process.env.REACT_APP_URL + "/aziende/getCandidatureAzienda/" + localStorage.getItem("partitaIva"), {
    headers: getAuthHeader(),
  })
    .then((res) => {
      if (!res.ok) {
        Toast.fire({ icon: "error", title: "Si è verificato un errore nel caricamento" });
        return null;
      }
      return res.json();
    })
    .then(
      (result) => {
        let arrayResult = [];
        let uuidCheck = [];

        result.forEach((element) => {
          switch (element.statoCandidatura) {
            case 0:
              element.statoLabel = "Disattivato";
              break;
            case 1:
              element.statoLabel = "Assunto";
              break;
            case 2:
              element.statoLabel = null;
              break;
            case 3:
              element.statoLabel = "In valutazione";
              break;
            case 4:
              element.statoLabel = "Rifiutata";
              break;
            default:
              element.statoLabel = null;
              break;
          }

          /*let filtro = result.filter((obj) => obj.uuidCandidatura === element.uuidCandidatura);
          let filtroCheckUuid = uuidCheck.filter((obj) => obj === element.uuidCandidatura);

          if (filtro.length > 0 && filtroCheckUuid.length === 0) {
            let datiAnagrafici = {};
            let documenti = {};

            filtro.forEach((objFiltro) => {
              if (objFiltro.dati) {
                if (objFiltro.sezione === "dati_anagrafici") {
                  datiAnagrafici = JSON.parse(objFiltro.dati);
                }

                if (objFiltro.sezione === "documenti") {
                  documenti = JSON.parse(objFiltro.dati);
                }
              }
            });

            element.documenti = documenti;
            element.datiAnagrafici = datiAnagrafici;

            arrayResult.push(element);
            uuidCheck.push(element.uuidCandidatura); 
          }*/
        });

        setCandidati(result);

        /*if (arrayResult) {
          setCandidatiBloccati(
                        arrayResult.filter((candidatura) => candidatura.sbloccato === 0 && candidatura.stato !== 0)
                    );
                    setCandidatiSbloccati(
                        arrayResult.filter((candidatura) => candidatura.sbloccato === 1 && candidatura.stato !== 0)
                    ); 

          setCandidati(arrayResult);
        }*/
      },
      (error) => {
        //reject(error);
      }
    );
}
