import { getAuthHeader } from "assets/utils/auth-services";
import { Toast } from "components/Toast";
import { uuidv4 } from "utils/utilsDati";

export function getAllInfoProfilo(
  authUser,
  setDatiAnagrafici,
  setTitoliStudio,
  setLingue,
  setEsperienzeLavorative,
  setLavoriInteresse,
  setDocumenti,
  tipologiaDocumento,
  setLoading
) {
  setLoading(true);

  fetch(process.env.REACT_APP_URL + "/persone/getAllInfoProfilo/" + authUser.codiceFiscale + "/" + tipologiaDocumento, {
    method: "GET",
    headers: getAuthHeader(),
  })
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      } else {
        Toast.fire({ icon: "error", title: "Si è verificato un problema nel caricamento dei dati del profilo" });
        setLoading(false);
      }
    })
    .then(
      (result) => {
        if (result) {
          setDatiAnagrafici(result?.anagrafica ? result?.anagrafica : []);
          setDocumenti(result?.documenti ? result?.documenti : []);
          setTitoliStudio(result?.titoliStudio ? result?.titoliStudio : []);
          setLingue(result?.lingue ? result?.lingue : []);
          setEsperienzeLavorative(result?.esperienzeLavorative ? result?.esperienzeLavorative : []);
          setLavoriInteresse(result?.lavoriInteresse ? result?.lavoriInteresse : []);
        }

        setLoading(false);
      },
      (error) => {
        Toast.fire({ icon: "error", title: "Si è verificato un problema nel caricamento dei dati del profilo" });
        setLoading(false);
      }
    );
}

export function salvaProfiloPersonaAnagrafica(authUser, dati, setAuthUser, dispatch) {
  if (authUser.codiceFiscale) {
    let headers = getAuthHeader();
    headers["Content-Type"] = "application/json";

    fetch(process.env.REACT_APP_URL + "/persone/salvaProfiloDatiAnagrafici/" + authUser.codiceFiscale, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(dati),
    }).then((res) => {
      if (res.status === 200) {
        if (dati.cognome) {
          authUser.cognome = dati.cognome;
        }

        if (dati.nome) {
          authUser.nome = dati.nome;
        }

        setAuthUser(dispatch, authUser);

        Toast.fire({ icon: "success", title: "Anagrafica Profilo salvata" });
      } else {
        Toast.fire({ icon: "error", title: "Si è verificato un errore nel salvataggio del profilo" });
      }
    });
  }
}

export function salvaProfiloPersonaTitoliStudio(authUser, dati) {
  if (authUser.codiceFiscale) {
    let headers = getAuthHeader();
    headers["Content-Type"] = "application/json";

    fetch(process.env.REACT_APP_URL + "/persone/salvaProfiloTitoliStudio/" + authUser.codiceFiscale, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(dati),
    }).then((res) => {
      if (res.status === 200) {
        Toast.fire({ icon: "success", title: "Titolo di studio salvato" });
      } else {
        Toast.fire({ icon: "error", title: "Si è verificato un errore nel salvataggio del profilo" });
      }
    });
  }
}

export function salvaProfiloPersonaLingue(authUser, dati) {
  if (authUser.codiceFiscale) {
    let headers = getAuthHeader();
    headers["Content-Type"] = "application/json";

    fetch(process.env.REACT_APP_URL + "/persone/salvaProfiloLingue/" + authUser.codiceFiscale, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(dati),
    }).then((res) => {
      if (res.status === 200) {
        Toast.fire({ icon: "success", title: "Lingue Salvate" });
      } else {
        Toast.fire({ icon: "error", title: "Si è verificato un errore nel salvataggio del profilo" });
      }
    });
  }
}

export function salvaProfiloPersonaEsperienzeLavorative(authUser, dati) {
  if (authUser.codiceFiscale) {
    let headers = getAuthHeader();
    headers["Content-Type"] = "application/json";

    fetch(process.env.REACT_APP_URL + "/persone/salvaProfilEsperienzeLavorative/" + authUser.codiceFiscale, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(dati),
    }).then((res) => {
      if (res.status === 200) {
        Toast.fire({ icon: "success", title: "Esperienze Lavorative salvate" });
      } else {
        Toast.fire({ icon: "error", title: "Si è verificato nel salvataggio del profilo" });
      }
    });
  }
}

export function salvaProfiloPersonaLavoriInteresse(authUser, dati) {
  if (authUser.codiceFiscale) {
    let headers = getAuthHeader();
    headers["Content-Type"] = "application/json";

    let array = [...dati];

    if (Array.isArray(array) && array.length > 0) {
      array.forEach((element) => {
        if (Array.isArray(element.orarioLavorativo) && element.orarioLavorativo.length > 0) {
          const result = element.orarioLavorativo.map((option) => option.value).join(",");
          element.orarioLavorativo = result;
        } else {
          element.orarioLavorativo = null;
        }
      });
    }

    fetch(process.env.REACT_APP_URL + "/persone/salvaProfiloLavoriInteresse/" + authUser.codiceFiscale, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(array),
    }).then((res) => {
      if (res.status === 200) {
        Toast.fire({ icon: "success", title: "Lavori d'interesse salvati" });
      } else {
        Toast.fire({ icon: "error", title: "Si è verificato un errore nel salvataggio del profilo" });
      }
    });
  }
}

export function salvaProfiloPersonaDocumenti(authUser, docsCv, docsLm, attestati, privacy, permessoDiSoggiorno, documenti, setDocumenti) {
  if (authUser.codiceFiscale) {
    let headers = getAuthHeader();
    headers["Content-Type"] = "application/json";

    let arrayDocumenti = [];
    const docTypes = {
      cv: docsCv,
      lm: docsLm,
      attestato: attestati,
      privacy: privacy,
      permesso_soggiorno: permessoDiSoggiorno,
    };

    // Create document objects and add them to the array
    Object.keys(docTypes).forEach((tipologia) => {
      const docs = docTypes[tipologia];
      docs.forEach((doc) => {
        if (doc) {
          const objDocumento = { tipologiaDocumento: tipologia, documento: doc, codiceFiscale: authUser.codiceFiscale };
          const existingDoc = documenti.find((d) => d.tipologiaDocumento === tipologia);

          // Add UUID if it exists
          if (existingDoc) {
            objDocumento.uuid = existingDoc.uuid;
          }
          arrayDocumenti.push(objDocumento);
        }
      });
    });

    fetch(process.env.REACT_APP_URL + "/persone/salvaProfiloPersonaDocumenti/" + authUser.codiceFiscale, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(arrayDocumenti),
    }).then((res) => {
      if (res.status === 200) {
        Toast.fire({ icon: "success", title: "Documenti salvati" });
        //setDocumenti(updatedDocumenti);
      } else {
        Toast.fire({ icon: "error", title: "Si è verificat un errore nel salvataggio del profilo" });
      }
    });
  }
}

export function salvaProfiloPersonaDocumento(authUser, documento, tipologiaDocumento, documenti, setDocumenti) {
  if (authUser.codiceFiscale) {
    let headers = getAuthHeader();
    headers["Content-Type"] = "application/json";

    let arrayDocumenti = [];

    let obj = { documento: documento, tipologiaDocumento: tipologiaDocumento, codiceFiscale: authUser.codiceFiscale };
    arrayDocumenti.push(obj);

    fetch(process.env.REACT_APP_URL + "/persone/salvaProfiloPersonaDocumenti/" + authUser.codiceFiscale, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(arrayDocumenti),
    }).then((res) => {
      if (res.status === 200) {
        Toast.fire({ icon: "success", title: "Documenti salvati" });

        let array = [...documenti];
        array.push(obj);
        setDocumenti(array);

        //setDocumenti(updatedDocumenti);
      } else {
        Toast.fire({ icon: "error", title: "Si è verificat un errore nel salvataggio del profilo" });
      }
    });
  }
}

export function deleteDocumentoProfilo(authUser, tipo, documenti, setDocumenti, setDocsCv) {
  if (authUser.codiceFiscale) {
    let headers = getAuthHeader();
    headers["Content-Type"] = "application/json";

    fetch(process.env.REACT_APP_URL + "/persone/deleteDocumentoProfilo/" + authUser.codiceFiscale + "/" + tipo, {
      headers: headers,
      method: "POST",
    }).then((res) => {
      if (res.status === 200) {
        Toast.fire({ icon: "success", title: "Documento cancellato" });

        let filter = documenti.filter((obj) => obj.tipologiaDocumento !== tipo);
        setDocumenti(filter);

        setDocsCv([]);
      } else {
        Toast.fire({ icon: "error", title: "Si è un errore nella cancellazione del documento del profilo" });
      }
    });
  }
}

export function deleteDocumentoProfiloByUuid(authUser, documenti, setDocumenti, setDocsCv, uuid) {
  if (authUser.codiceFiscale) {
    let headers = getAuthHeader();
    headers["Content-Type"] = "application/json";

    fetch(process.env.REACT_APP_URL + "/persone/deleteDocumentoProfiloByUuid/" + authUser.codiceFiscale + "/" + uuid, {
      headers: headers,
      method: "POST",
    }).then((res) => {
      if (res.status === 200) {
        Toast.fire({ icon: "success", title: "Documento cancellato" });

        let filter = documenti.filter((obj) => obj.uuid !== uuid);
        setDocumenti(filter);

        setDocsCv([]);
      } else {
        Toast.fire({ icon: "error", title: "Si è un errore nella cancellazione del documento del profilo" });
      }
    });
  }
}
