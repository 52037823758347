import React, { useState } from "react";
import { Grid, Typography, List, Divider } from "@mui/material";
import coloriVetrina from "../../json/coloriVetrina.json";
import ChipsFiltriComuni from "./chips_filtri/ChipsFiltriComuni";
import ChipsFiltriSettori from "./chips_filtri/ChipsFiltriSettori";
import ViewAnnuncio from "./ViewAnnuncio";
import { useJobErController } from "context";
import { verificaVisibilitaAnnuncio } from "./utils/utils_annuncio";
import { handleCandidatura } from "./utils/utils_candidatura";

function ListaAnnunci(props) {
  const [controller] = useJobErController();
  const { authUser } = controller;
  const [pending, setPending] = useState(false);

  return (
    <Grid container mt={1} mb={2} spacing={2} justifyContent="center">
      {props.filtri && Object.keys(props.filtri).length > 0 ? (
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={2}>
            {props.filtri?.provincia ? <ChipsFiltriComuni provincia={props.filtri.provincia} /> : null}
            {props.filtri?.comune ? <ChipsFiltriComuni comuni={props.filtri.comune} /> : null}
            {props.filtri?.professione ? <ChipsFiltriSettori settori={props.filtri.professione} /> : null}
          </Grid>
        </Grid>
      ) : null}
      {props.annunci ? (
        <Grid item xs={12}>
          <Typography variant="h7" fontWeight="bold" style={{ color: coloriVetrina.blue }}>
            Annunci trovati: {props.annunci.length}
          </Typography>
        </Grid>
      ) : null}
      <Grid item xs={12}>
        {props.annunci ? (
          <List
            sx={{
              width: "100%",
              maxHeight: "600px",
              overflowY: "auto",
            }}
          >
            {props.annunci.map((annuncio, index) => (
              <React.Fragment key={index}>
                {verificaVisibilitaAnnuncio(props.filtri, annuncio) ? (
                  <React.Fragment key={"view_" + index}>
                    <ViewAnnuncio
                      annuncio={annuncio}
                      index={index}
                      pending={pending}
                      searchParams={props.searchParams}
                      onSendCandidatura={() => {
                        handleCandidatura(annuncio, authUser, props.annunci, props.setAnnunci, setPending);
                      }}
                    />
                    <>
                      <br></br>
                      <Divider key={"divider_" + index} />
                      <br></br>
                    </>
                  </React.Fragment>
                ) : null}
              </React.Fragment>
            ))}
          </List>
        ) : null}
      </Grid>
    </Grid>
  );
}
export default ListaAnnunci;
