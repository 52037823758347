import { getAuthHeader } from "assets/utils/auth-services";
import { Toast } from "components/Toast";

export function handleCandidatura(candidatura, user, annunci, setAnnunci, setPending) {
  if (candidatura && user) {
    let obj = {
      uuidAnnuncio: candidatura.uuid,
      codiceFiscale: user.codiceFiscale,
      nominativo: user.nome + " " + user.cognome,
      user: user.email,
    };

    let headers = getAuthHeader();
    headers["Content-Type"] = "application/json";

    const params = new URLSearchParams({
      linkAmbiente: process.env.REACT_APP_URL_FE,
      ambiente: process.env.REACT_APP_AMBIENTE,
    });

    setPending(true);

    fetch(process.env.REACT_APP_URL + "/persone/salvaCandidaturaPersona?" + params, {
      headers: headers,
      method: "POST",
      body: JSON.stringify(obj),
    }).then((res) => {
      if (res.status === 200) {
        let filter = annunci.filter((obj) => obj.uuid !== candidatura.uuid);
        setAnnunci(filter);

        Toast.fire({ icon: "success", title: "La tua candidatura è stata inviata con successo" });
        setPending(false);
      } else {
        Toast.fire({ icon: "error", title: "Si è verificato un errore nel salvataggio della candidatura" });
        setPending(false);
      }
    });
  }
}

export function eliminaCandidaturaAnnuncio(setElencoAnnunci, elencoAnnunci, annuncio) {
  let candidatura = annuncio.candidature[0];
  candidatura.stato = 0;

  let headers = getAuthHeader();
  headers["Content-Type"] = "application/json";

  fetch(process.env.REACT_APP_URL + "/persone/salvaCandidaturaPersona", {
    headers: headers,
    method: "POST",
    body: JSON.stringify(candidatura),
  }).then((res) => {
    if (res.status === 200) {
      setElencoAnnunci(() => {
        const updatedAnnunci = elencoAnnunci.map((obj) => {
          if (obj.uuid === annuncio.uuid) {
            obj.candidature = null;
            return obj;
          } else {
            return obj;
          }
        });

        return updatedAnnunci;
      });

      Toast.fire({ icon: "success", title: "La tua candidatura è stata revocata con successo" });
    } else {
      Toast.fire({ icon: "error", title: "Si è verificato un errore nella revoca della candidatura" });
    }
  });
}

export function eliminaCandidaturaCandidatura(setCandidature, candidature, candidatura) {
  let obj = {};

  if (candidatura.uuidAnnuncio !== null) {
    obj.tipo = "annuncio";
    obj.uuidCandidatura = candidatura.uuidCandidatura;
  } else {
    obj.tipo = "spontanea";
    obj.uuidCandidatura = candidatura.uuidCandidatura;
  }

  let headers = getAuthHeader();
  headers["Content-Type"] = "application/json";

  fetch(process.env.REACT_APP_URL + "/persone/disattivaCandidaturaPersona", {
    headers: headers,
    method: "POST",
    body: JSON.stringify(obj),
  }).then((res) => {
    if (res.status === 200) {
      let candidatureFilter = candidature.filter((obj) => obj.uuidCandidatura !== candidatura.uuidCandidatura);

      setCandidature(candidatureFilter);

      Toast.fire({ icon: "success", title: "La tua candidatura è stata revocata con successo" });
    } else {
      Toast.fire({ icon: "error", title: "Si è verificato un errore nella revoca della candidatura" });
    }
  });
}

export function getCandidaturePersona(authUser, setCandidature, setLoading) {
  setLoading(true);

  fetch(process.env.REACT_APP_URL + "/persone/getHomeCandidaturePersona/" + authUser.codiceFiscale, {
    headers: getAuthHeader(),
  })
    .then((res) => {
      if (!res.ok) {
        Toast.fire({ icon: "error", title: "Si è verificato un errore nel caricamento" });
        return null;
      }
      return res.json();
    })
    .then(
      (result) => {
        setLoading(false);
        setCandidature(result);
      },
      (error) => {
        setLoading(false);
        console.error("Si è verificato un errore");
        Toast.fire({ icon: "error", title: "Si è verificato un errore" });
      }
    );
}

export function insertCandidaturaSpontanea(azienda, authUser, setOpen) {
  let nominativo =
    (authUser?.nome ? authUser.nome.toUpperCase() : "") +
    (authUser?.nome && authUser?.cognome ? " " : "") +
    (authUser?.cognome ? authUser.cognome.toUpperCase() : "");

  let obj = {
    email: authUser.email,
    codiceFiscale: authUser.codiceFiscale,
    stato: 3,
    nominativo: nominativo,
    piva: azienda.partitaIva,
    codiceMetopack: azienda.codiceMetopack,
  };

  let headers = getAuthHeader();
  headers["Content-Type"] = "application/json";

  const params = new URLSearchParams({
    linkAmbiente: process.env.REACT_APP_URL_FE,
    ambiente: process.env.REACT_APP_AMBIENTE,
  });

  fetch(process.env.REACT_APP_URL + "/persone/insertCandidaturaSpontanea?" + params, {
    method: "POST",
    headers: headers,
    body: JSON.stringify(obj),
  }).then(
    (res) => {
      if (res.status === 200) {
        setOpen(false);
        Toast.fire({ icon: "success", title: "Candidatura inviata" });
      } else {
        Toast.fire({ icon: "error", title: "Candidatura già effettuata" });
      }
    },
    (error) => {
      console.error("Si è verificato un errore nell'invio della candidatura");
      Toast.fire({ icon: "error", title: "Si è verificato un errore nell'invio della candidatura" });
    }
  );
}
