import React from "react";
import JobErCard from "components/JobErCard";
import { Divider, Grid, Typography } from "@mui/material";
import JobErInput from "components/JobErInput";
import coloriVetrina from "../../json/coloriVetrina.json";
import JobErAsyncSelect from "components/JobErAsyncSelect";
import { asyncRicercaComuni } from "utils/utilsRicerca";
import JobErSelect from "components/JobErSelect";
import options from "../../json/option_configuration.json";
import { dataNascitaDaCodiceFiscale } from "utils/utilsDati";
import JobButton from "components/JobButton";

function CardProfiloDatiAnagrafici(props) {
  return (
    <JobErCard
      title="Dati Anagrafici"
      style={{ minHeight: "300px" }}
      child={
        <Grid container justifyContent="center" spacing={2} mt={1}>
          <Grid item xs={12} md={6}>
            <JobErInput
              label="Nome"
              coloreTesto={coloriVetrina.black}
              coloreBordo={coloriVetrina.gray}
              size="small"
              value={props.dati?.nome || props.authUser?.nome || ""}
              onChange={(e) => props.onSetDati("nome", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <JobErInput
              label="Cognome"
              coloreTesto={coloriVetrina.black}
              coloreBordo={coloriVetrina.gray}
              size="small"
              value={props.dati?.cognome || props.authUser?.cognome || ""}
              onChange={(e) => props.onSetDati("cognome", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <JobErInput
              label="Codice Fiscale"
              coloreTesto={coloriVetrina.black}
              coloreBordo={coloriVetrina.gray}
              size="small"
              disabled={true}
              value={props.authUser?.codiceFiscale || props.dati?.codiceFiscale || ""}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <JobErInput
              label="Data Nascita"
              coloreTesto={coloriVetrina.black}
              coloreBordo={coloriVetrina.gray}
              size="small"
              disabled={true}
              value={
                props.authUser?.codiceFiscale
                  ? dataNascitaDaCodiceFiscale(props.authUser.codiceFiscale)
                  : props.dati?.codiceFiscale
                  ? dataNascitaDaCodiceFiscale(props.dati.codiceFiscale)
                  : ""
              }
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <JobErInput
              label="Email"
              coloreTesto={coloriVetrina.black}
              coloreBordo={coloriVetrina.gray}
              size="small"
              disabled={true}
              value={props.authUser?.email || props.dati?.email || ""}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <JobErInput
              label="Telefono"
              coloreTesto={coloriVetrina.black}
              coloreBordo={coloriVetrina.gray}
              size="small"
              value={props.dati?.telefono || ""}
              onChange={(e) => props.onSetDati("telefono", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <JobErAsyncSelect
              mode="white"
              menuPosition="fixed"
              isMulti={false}
              isClearable="true"
              placeholder={"Comune Residenza"}
              onChange={(data) => props.onSetDati("comuneResidenza", data.label)}
              value={
                props.dati?.comuneResidenza
                  ? {
                      value: props.dati.comuneResidenza,
                      label: props.dati.comuneResidenza,
                    }
                  : null
              }
              loadOptions={asyncRicercaComuni}
              noOptionsMessage={() => "Nessun comune trovato"}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <JobErInput
              label="Indirizzo Residenza"
              coloreTesto={coloriVetrina.black}
              coloreBordo={coloriVetrina.gray}
              size="small"
              value={props.dati?.indirizzo || ""}
              onChange={(e) => props.onSetDati("indirizzo", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} md={6} textAlign="left">
            <JobErSelect
              mode="white"
              menuPosition="fixed"
              isClearable="true"
              placeholder={"Stato Civile"}
              onChange={(data) => {
                props.onSetDati("statoCivile", data.value);
              }}
              value={props.dati?.statoCivile ? options.stato_civile.filter((opt) => opt.value === props.dati.statoCivile) : null}
              options={options.stato_civile}
            />
          </Grid>
          <Grid item xs={12} md={6} textAlign="left">
            <JobErSelect
              mode="white"
              menuPosition="fixed"
              isClearable="true"
              placeholder={"Patente"}
              onChange={(data) => {
                var parseData = data.map((patente) => patente.value);
                props.onSetDati("patenti", parseData.toString());
              }}
              value={props.dati?.patenti ? options.patenti.filter((opt) => props.dati.patenti.split(",").includes(opt.value)) : null}
              options={options.patenti}
              isMulti={true}
            />
          </Grid>
          <Grid item xs={12} m={1.5}>
            <Divider />
          </Grid>
          <Grid item xs={12} md={12}>
            <Typography
              variant="h6"
              color={coloriVetrina.error}
              sx={{
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              *Solo per lavoratori stranieri
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} textAlign="left">
            <JobErSelect
              mode="white"
              menuPosition="fixed"
              isClearable="true"
              placeholder={"Permesso Soggiorno"}
              onChange={(data) => {
                props.onSetDati("permessoSoggiorno", data.value);
              }}
              value={props.dati?.permessoSoggiorno ? options.permesso_soggiorno.filter((opt) => opt.value === props.dati.permessoSoggiorno) : null}
              options={options.permesso_soggiorno}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <JobErInput
              label="Data Scadenza"
              type="date"
              coloreTesto={coloriVetrina.black}
              coloreBordo={coloriVetrina.gray}
              size="small"
              value={props.dati?.dataScadenzaPermessoSoggiorno ? props.dati.dataScadenzaPermessoSoggiorno : " "}
              onChange={(e) => props.onSetDati("dataScadenzaPermessoSoggiorno", e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} mt={2}>
            <Grid container justifyContent="center">
              <Grid item xs="auto">
                <JobButton label="Salva" colorLabel={coloriVetrina.green} colorBorder={coloriVetrina.green} onClick={() => props.onSave()} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
}
export default CardProfiloDatiAnagrafici;
