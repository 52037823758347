import React from "react";
import { Grid, Typography, ListItemAvatar, ListItemText, Avatar, ListItemButton } from "@mui/material";
import coloriVetrina from "json/coloriVetrina.json";
import { dataNascitaDaCodiceFiscale } from "utils/utilsDati";
import JobButton from "components/JobButton";
import { downloadDocumento } from "utils/utilsDati";
import { setStatisticheDownloadCv } from "components/PortaleAziende/utils/utils_candidature";
import { useJobErController, setAuthUser } from "context";

function ViewCandidatura(props) {
  const [controller, dispatch] = useJobErController();
  const { authUser } = controller;

  const apriDettaglio = (uuid) => {
    if (uuid) {
      props.setOpenDettaglio(uuid);
    }
  };

  return (
    <ListItemButton
      sx={{ backgroundColor: props.candidatura.uuidCandidatura === props.uuidParam ? coloriVetrina.searchSelected : "#fff" }}
      alignItems="flex-start"
      key={props.candidatura.uuidCandidatura}
      autoFocus={props.candidatura.uuidCandidatura === props.uuidParam}
    >
      <ListItemAvatar>
        {props.candidatura?.immagineProfilo ? (
          <Avatar src={props.candidatura.immagineProfilo}>N</Avatar>
        ) : (
          <Avatar
            sx={{
              fontWeight: "bold",
              backgroundColor: coloriVetrina.blue,
            }}
          >
            {props.candidatura?.nominativo ? props.candidatura.nominativo.substring(0, 1) : null}
          </Avatar>
        )}
      </ListItemAvatar>
      <ListItemText
        key={"_text"}
        sx={{ marginLeft: 2 }}
        primary={
          props.candidatura.nominativo
            ? props.candidatura.nominativo.toUpperCase() +
              " - " +
              (props.candidatura.titoloAnnuncio ? props.candidatura.titoloAnnuncio.toLowerCase() : "candidatura spontanea")
            : null
        }
        primaryTypographyProps={{
          fontWeight: "bold",
          color: coloriVetrina.blue,
        }}
        secondary={
          <Grid container>
            <Grid item xs="auto">
              <Typography color={coloriVetrina.blue} variant="subtitle1">
                Candidature: <strong>{props.candidati.filter((obj) => obj.codiceFiscale === props.candidatura.codiceFiscale).length}</strong>
              </Typography>
            </Grid>
            <Grid item xs="auto" ml={2}>
              <JobButton
                label={"Dettaglio"}
                onClick={() => {
                  apriDettaglio(props.candidatura.uuidCandidatura);
                }}
              />
            </Grid>
            <Grid item xs="auto" ml={2}>
              <JobButton label={props.candidatura.statoLabel} cursor={"not-allowed"} />
            </Grid>
            {props.candidatura?.documento && (
              <Grid item xs="auto" ml={2}>
                <JobButton
                  label={"CV"}
                  onClick={() => {
                    let nomeFile = props.candidatura.nominativo?.toUpperCase() + " - " + props.candidatura.titoloAnnuncio?.toLowerCase();

                    downloadDocumento(props.candidatura.documento, nomeFile);
                    setStatisticheDownloadCv(authUser);
                  }}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              p={1}
              id={props.candidatura.uuidCandidatura}
              sx={{ display: props.openDettaglio === props.candidatura.uuidCandidatura ? "inline" : "none" }}
            >
              <Grid container>
                <Grid item xs={12} md={6} p={0.5}>
                  <Typography color={coloriVetrina.blue} variant="subtitle1">
                    Codice Fiscale: <strong>{props.candidatura?.codiceFiscaleCandidato}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} p={0.5}>
                  <Typography color={coloriVetrina.blue} variant="subtitle1">
                    Data di Nascita: <strong>{dataNascitaDaCodiceFiscale(props.candidatura?.codiceFiscaleCandidato)}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} p={0.5}>
                  <Typography color={coloriVetrina.blue} variant="subtitle1">
                    Telefono: <strong>{props.candidatura?.telefono}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} p={0.5}>
                  <Typography color={coloriVetrina.blue} variant="subtitle1">
                    Mail: <strong>{props.candidatura?.user}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} p={0.5}>
                  <Typography color={coloriVetrina.blue} variant="subtitle1">
                    Comune Residenza: <strong>{props.candidatura?.comuneResidenza}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} p={0.5}>
                  <Typography color={coloriVetrina.blue} variant="subtitle1">
                    Indirizzo Residenza: <strong>{props.candidatura?.indirizzo}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} p={0.5}>
                  <Typography color={coloriVetrina.blue} variant="subtitle1">
                    Stato Civile: <strong>{props.candidatura?.statoCivile}</strong>
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} p={0.5}>
                  <Typography color={coloriVetrina.blue} variant="subtitle1">
                    Patente: <strong>{props.candidatura?.patenti}</strong>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    </ListItemButton>
  );
}

export default ViewCandidatura;
