import LayoutPersone from "components/PortalePersone/LayoutPersone";
import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import CardProfiloDatiAnagrafici from "components/PortalePersone/CardProfiloDatiAnagrafici";
import CardProfiloTitoliStudio from "components/PortalePersone/CardProfiloTitoliStudio";
import CardProfiloLingue from "components/PortalePersone/CardProfiloLingue";
import CardProfiloEsperienzeLavorative from "components/PortalePersone/CardProfiloEsperienzeLavorative";
import CardProfiloLavoriInteresse from "components/PortalePersone/CardProfiloLavoriInteresse";
import CardProfiloDocumenti from "components/PortalePersone/CardProfiloDocumenti";
import CardProfiloFiltriMobile from "components/PortalePersone/CardProfiloFiltriMobile";
import { useJobErController, setAuthUser } from "context";
import { uuidv4 } from "utils/utilsDati";
import {
  salvaProfiloPersonaAnagrafica,
  salvaProfiloPersonaTitoliStudio,
  salvaProfiloPersonaLingue,
  salvaProfiloPersonaEsperienzeLavorative,
  salvaProfiloPersonaLavoriInteresse,
  salvaProfiloPersonaDocumenti,
  getAllInfoProfilo,
} from "components/PortalePersone/utils/utils_profilo";
import JobLoading from "components/JobLoading";

function ProfiloPersone(props) {
  const [datiAnagrafici, setDatiAnagrafici] = useState({});
  const [titoliStudio, setTitoliStudio] = useState([]);
  const [lingue, setLingue] = useState([]);
  const [esperienzeLavorative, setEsperienzeLavorative] = useState([]);
  const [lavoriInteresse, setLavoriInteresse] = useState([]);
  const [documenti, setDocumenti] = useState([]);
  const [loading, setLoading] = useState(false);

  const [controller, dispatch] = useJobErController();
  const { authUser } = controller;

  useEffect(() => {
    if (authUser?.codiceFiscale) {
      getAllInfoProfilo(authUser, setDatiAnagrafici, setTitoliStudio, setLingue, setEsperienzeLavorative, setLavoriInteresse, setDocumenti, "cv", setLoading);
    }
  }, [authUser]);

  return (
    <>
      {loading ? (
        <JobLoading />
      ) : (
        <LayoutPersone
          child={
            <Grid
              container
              spacing={2}
              style={{}}
              sx={{
                padding: { xs: 2, md: 0 },
                backgroundColor: "#002b48!important",
              }}
            >
              <Grid item xs={12} sx={{ display: { xs: "block", md: "none" } }}>
                <CardProfiloFiltriMobile />
              </Grid>
              <Grid item xs={12} md={4}>
                <CardProfiloDatiAnagrafici
                  dati={datiAnagrafici}
                  authUser={authUser}
                  onSetDati={(key, val) => setDatiAnagrafici({ ...datiAnagrafici, [key]: val })}
                  onSave={() => {
                    salvaProfiloPersonaAnagrafica(authUser, datiAnagrafici, setAuthUser, dispatch);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CardProfiloTitoliStudio
                  dati={titoliStudio}
                  onSetNestedDati={(uuid, key, val) => {
                    const updatedItems = titoliStudio.map((item) => (item.uuid === uuid ? { ...item, [key]: val } : item));
                    setTitoliStudio(updatedItems);
                  }}
                  onAddNestedObject={() => {
                    let array = [...titoliStudio];
                    array.push({ uuid: uuidv4() });
                    setTitoliStudio(array);
                  }}
                  onRemoveNestedData={(uuid) => {
                    let array = [...titoliStudio];
                    array = array.filter((espLav) => espLav.uuid !== uuid);
                    setTitoliStudio(array);
                  }}
                  onSave={() => {
                    salvaProfiloPersonaTitoliStudio(authUser, titoliStudio);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CardProfiloLingue
                  dati={lingue}
                  onSetDati={(key, val) => {
                    const updatedItems = lingue.map((item) => (item.ordine === 1 ? { ...item, [key]: val, ordine: 1 } : item));
                    setLingue(updatedItems);
                  }}
                  onSetNestedDati={(uuid, key, val) => {
                    const updatedItems = lingue.map((item) => (item.uuid === uuid ? { ...item, [key]: val, ordine: null } : item));
                    setLingue(updatedItems);
                  }}
                  onAddNestedObject={() => {
                    let array = [...lingue];
                    array.push({ uuid: uuidv4() });
                    setLingue(array);
                  }}
                  onRemoveNestedData={(uuid) => {
                    let array = [...lingue];
                    array = array.filter((lingua) => lingua.uuid !== uuid);
                    setLingue(array);
                  }}
                  onSave={() => {
                    salvaProfiloPersonaLingue(authUser, lingue);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CardProfiloEsperienzeLavorative
                  dati={esperienzeLavorative}
                  onSetDati={(key, val) => {
                    const updatedItems = esperienzeLavorative.map((item) => (item.ordine === 1 ? { ...item, [key]: val, ordine: 1 } : item));
                    setEsperienzeLavorative(updatedItems);
                  }}
                  onSetNestedDati={(uuid, key, val) => {
                    const updatedItems = esperienzeLavorative.map((item) => (item.uuid === uuid ? { ...item, [key]: val, ordine: null } : item));
                    setEsperienzeLavorative(updatedItems);
                  }}
                  onAddNestedObject={() => {
                    let array = [...esperienzeLavorative];
                    array.push({ uuid: uuidv4() });
                    setEsperienzeLavorative(array);
                  }}
                  onRemoveNestedData={(uuid) => {
                    let array = [...esperienzeLavorative];
                    array = array.filter((espLav) => espLav.uuid !== uuid);
                    setEsperienzeLavorative(array);
                  }}
                  onSave={() => {
                    salvaProfiloPersonaEsperienzeLavorative(authUser, esperienzeLavorative);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CardProfiloLavoriInteresse
                  dati={lavoriInteresse}
                  onSetNestedDati={(uuid, key, val) => {
                    const updatedItems = lavoriInteresse.map((item) => (item.uuid === uuid ? { ...item, [key]: val } : item));
                    setLavoriInteresse(updatedItems);
                  }}
                  onAddNestedObject={() => {
                    let array = [...lavoriInteresse];
                    array.push({ uuid: uuidv4() });
                    setLavoriInteresse(array);
                  }}
                  onRemoveNestedData={(uuid) => {
                    let array = [...lavoriInteresse];
                    array = array.filter((espLav) => espLav.uuid !== uuid);
                    setLavoriInteresse(array);
                  }}
                  onSave={() => {
                    salvaProfiloPersonaLavoriInteresse(authUser, lavoriInteresse);
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <CardProfiloDocumenti
                  dati={documenti}
                  setDati={setDocumenti}
                  authUser={authUser}
                  onSave={(docsCv, docsLm, attestati, privacy, permessoDiSoggiorno) => {
                    salvaProfiloPersonaDocumenti(authUser, docsCv, docsLm, attestati, privacy, permessoDiSoggiorno, documenti, setDocumenti);
                  }}
                />
              </Grid>
            </Grid>
          }
        />
      )}
    </>
  );
}
export default ProfiloPersone;
