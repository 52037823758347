import LayoutPersone from "components/PortalePersone/LayoutPersone";
import React, { useEffect, useState } from "react";
import coloriVetrina from "../../json/coloriVetrina.json";
import Slider from "react-slick";
import SliderWrapper from "components/PortalePersone/_SlickSliderStyle";
import { Grid, Typography } from "@mui/material";
import CardAnnunciPersone from "components/PortalePersone/CardAnnunciPersone";
import CardAziendeHomepage from "components/PortalePersone/CardAziendeHomepage";
import CardCandidatureHomepage from "components/PortalePersone/CardCandidatureHomepage";
import { getElencoUltimiAnnunci } from "components/PortalePersone/utils/utils_annuncio";
import JobButton from "components/JobButton";
import { useNavigate } from "react-router-dom";
import { decodeHTMLEntities } from "utils/utilsDati";
import JobLoading from "components/JobLoading";

function HomepagePersone(props) {
  const navigate = useNavigate();
  const [ultimiAnnunci, setUltimiAnnunci] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getElencoUltimiAnnunci(setUltimiAnnunci, setLoading);
  }, []);
  return (
    <>
      {loading ? (
        <JobLoading />
      ) : (
        <LayoutPersone
          child={
            <>
              <Grid
                container
                spacing={3}
                sx={{
                  paddingLeft: { xs: 3, md: 0 },
                  paddingRight: { xs: 3, md: 0 },
                }}
              >
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    color={coloriVetrina.white + "!important"}
                    sx={{
                      textAlign: "left!important",
                      fontWeight: "bold",
                      fontSize: "24px",
                    }}
                  >
                    Ultimi annunci pubblicati sul portale
                  </Typography>
                </Grid>
                <Grid item xs={12} textAlign="left" style={{ paddingTop: 5 }}>
                  <JobButton
                    label="Visualizza tutti"
                    colorBackground={coloriVetrina.blue}
                    colorLabel={coloriVetrina.white}
                    onClick={() => navigate("/Persone/Annunci")}
                  />
                </Grid>
                <Grid item xs={12} mt={1} style={{ paddingTop: 5 }} sx={{ display: { xs: "none", md: "block" } }}>
                  <SliderWrapper>
                    <Slider
                      dots={true}
                      infinite={true}
                      speed={500}
                      slidesToShow={6}
                      slidesToScroll={6}
                      waitForAnimate={true}
                      arrows={false}
                      swipe={true}
                      autoplay={true}
                      autoplaySpeed={5000}
                      appendDots={(dots) => <ul>{dots}</ul>}
                      customPaging={(i) => (
                        <div className="ft-slick__dots--custom">
                          <div className="loading" />
                        </div>
                      )}
                    >
                      {ultimiAnnunci && ultimiAnnunci.length > 0
                        ? ultimiAnnunci.map((annuncio, index) => {
                            return (
                              <div key={annuncio + "_" + index}>
                                <CardAnnunciPersone
                                  gruppoLavoro={annuncio.posizioneLavorativa?.gruppo ? annuncio.posizioneLavorativa.gruppo : null}
                                  mansione={annuncio.posizioneLavorativa ? annuncio.posizioneLavorativa.descrizione : null}
                                  titolo={annuncio.titoloAnnuncio}
                                  descrizioneLavoro={decodeHTMLEntities(annuncio.descrizioneLavoro)}
                                  comune={annuncio.comune ? annuncio.comune.des : null}
                                  posizioniAperte={annuncio.posizioniAperte}
                                  prov={annuncio.comune ? annuncio.comune.prov : null}
                                  uuid={annuncio.uuid}
                                />
                              </div>
                            );
                          })
                        : null}
                    </Slider>
                  </SliderWrapper>
                </Grid>
                <Grid item xs={12} mt={1} style={{ paddingTop: 5 }} sx={{ display: { xs: "block", md: "none" } }}>
                  <SliderWrapper>
                    <Slider
                      dots={true}
                      infinite={true}
                      speed={500}
                      slidesToShow={1}
                      slidesToScroll={1}
                      waitForAnimate={true}
                      arrows={false}
                      centerMode={true}
                      swipe={true}
                      autoplay={true}
                      autoplaySpeed={5000}
                      appendDots={(dots) => <ul>{dots}</ul>}
                      customPaging={(i) => (
                        <div className="ft-slick__dots--custom">
                          <div className="loading" />
                        </div>
                      )}
                    >
                      {ultimiAnnunci && ultimiAnnunci.length > 0
                        ? ultimiAnnunci.map((annuncio, index) => {
                            return (
                              <div key={annuncio + "_" + index}>
                                <CardAnnunciPersone
                                  gruppoLavoro={annuncio.codificaMansione ? annuncio.codificaMansione.gruppo : null}
                                  mansione={annuncio.codificaMansione ? annuncio.codificaMansione.descrizione : null}
                                  titolo={annuncio.titoloAnnuncio}
                                  descrizioneLavoro={annuncio.descrizioneLavoro}
                                  comune={annuncio.comune ? annuncio.comune.des : null}
                                  posizioniAperte={annuncio.posizioniAperte}
                                  prov={annuncio.comune ? annuncio.comune.prov : null}
                                />
                              </div>
                            );
                          })
                        : null}
                    </Slider>
                  </SliderWrapper>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                mt={3}
                mb={3}
                sx={{
                  paddingLeft: { xs: 3, md: 0 },
                  paddingRight: { xs: 3, md: 0 },
                }}
              >
                <Grid item xs={12} md={6}>
                  <CardAziendeHomepage />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CardCandidatureHomepage />
                </Grid>
              </Grid>
            </>
          }
        />
      )}
    </>
  );
}
export default HomepagePersone;
