import JobErModal from "components/JobErModal";
import { Grid } from "@mui/material";
import JobButton from "components/JobButton";
import JobTypography from "components/JobTypography";
import PropTypes from "prop-types";
import { insertCandidaturaSpontanea } from "components/PortalePersone/utils/utils_candidatura";
import { useJobErController } from "context";
import coloriVetrina from "json/coloriVetrina.json";

function CardAziendeHomepageDettaglio(props) {
  const [controller] = useJobErController();
  const { authUser } = controller;

  return (
    <JobErModal
      fullWidth={true}
      maxWidth={"sm"}
      open={props.open}
      onClose={() => {
        props.setOpen(false);
      }}
      modalBody={
        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12} md={6} textAlign="center">
            <img
              alt="Copertina Azienda"
              src={props.azienda?.copertina}
              style={{
                maxHeight: "200px",
                width: "100%",
                borderRadius: 15,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <JobTypography testo={props.azienda?.ragioneSociale ? props.azienda.ragioneSociale : ""} tipo="titoloLista" />
          </Grid>
          <Grid item xs={12}>
            <JobTypography
              testo={props.azienda?.descrizione ? <div dangerouslySetInnerHTML={{ __html: props.azienda.descrizione }} /> : ""}
              tipo="descrizioneLista"
            />
          </Grid>
          {/* <Grid item xs={12}>
            <JobTypography testo={(props.azienda?.indirizzo ? props.azienda?.indirizzo : "") + ", " + (props.azienda?.com?.des ? props.azienda?.com?.des : "")} tipo="descrizioneLista" />
          </Grid> */}
          {props.azienda?.sitoWeb && props.azienda?.sitoWeb !== "https://" && (
            <Grid item xs={12}>
              <a href={props.azienda?.sitoWeb && props.azienda?.sitoWeb !== "https://" ? props.azienda.sitoWeb : ""} target="_blank" rel="noreferrer">
                Sito web
              </a>
            </Grid>
          )}
        </Grid>
      }
      modalFooter={
        <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <JobButton
              label={"Candidatura Spontanea"}
              onClick={() => {
                insertCandidaturaSpontanea(props.azienda, authUser, props.setOpen);
              }}
            />
            <JobButton
              colorBackground={coloriVetrina.error}
              colorBorder={coloriVetrina.error}
              colorLabel={coloriVetrina.white}
              label={"Chiudi"}
              onClick={() => {
                props.setOpen(false);
              }}
            />
          </Grid>
        </Grid>
      }
    />
  );
}

CardAziendeHomepageDettaglio.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  azienda: PropTypes.object,
};

export default CardAziendeHomepageDettaglio;
