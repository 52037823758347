import moment from "moment";
import { getAuthHeader } from "assets/utils/auth-services";
import { Toast } from "components/Toast";

export function verificaVisibilitaAnnuncio(filtri, annuncio) {
  if (filtri.comune && filtri.comune.length > 0 && annuncio.comuneLavoro) {
    if (filtri.comune.filter((com) => com.value === annuncio.comuneLavoro).length === 0) {
      return false;
    }
  }

  if (filtri.provincia && filtri.provincia.length > 0 && annuncio?.comune?.prov) {
    if (filtri.provincia.filter((com) => com.value === annuncio.comune.prov).length === 0) {
      return false;
    }
  }
  if (filtri?.professione && filtri.professione.length > 0 && annuncio?.codificaMansione?.uuid) {
    if (filtri.professione.filter((com) => com.uuid === annuncio.codificaMansione.uuid).length === 0) {
      return false;
    }
  }
  return true;
}

function dateInCurrentWeek(date) {
  const now = moment();
  const input = moment(date);
  return now.isoWeek() === input.isoWeek();
}

function isDateInLast6Months(date) {
  // Converti la data in un oggetto Moment
  const momentDate = moment(date);
  // Controlla se la data è negli ultimi 6 mesi
  return momentDate.isAfter(moment().subtract(6, "months"));
}

export function getElencoAnnunci(authUser, setElencoAnnunci, setLoading) {
  setLoading(true);

  fetch(process.env.REACT_APP_URL + "/persone/elencoAnnunci/" + authUser.codiceFiscale, {
    method: "GET",
    headers: getAuthHeader(),
  })
    .then((res) => {
      if (!res.ok) {
        Toast.fire({ icon: "error", title: "Si è verificato un errore nel caricamento" });
        return null;
      }
      return res.json();
    })
    .then(
      (result) => {
        setLoading(false);
        setElencoAnnunci(result);
      },
      (error) => {
        setLoading(false);
        Toast.fire({ icon: "error", title: "Si è verificato un errore nel caricamento degli annunci" });
      }
    );
}

export function getElencoUltimiAnnunci(setUltimiAnnunci, setLoading) {
  setLoading(true);
  fetch(process.env.REACT_APP_URL + "/vetrina/elencoUltimiAnnunci", {
    method: "GET",
  })
    .then((res) => {
      if (!res.ok) {
        Toast.fire({ icon: "error", title: "Si è verificato un errore nel caricamento" });
        return null;
      }
      return res.json();
    })
    .then(
      (result) => {
        setLoading(false);
        setUltimiAnnunci(result);
      },
      (error) => {
        setLoading(false);
        console.error("Si è verificato un errore nel caricamento degli annunci");
        Toast.fire({ icon: "error", title: "Si è verificato un errore nel caricamento degli annunci" });
      }
    );
}
