import { Buffer } from "buffer";
import { getAuthHeader } from "assets/utils/auth-services";
import { Toast } from "components/Toast";

const campiRegPersona = [
  "email",
  "password",
  "ripetiPassword",
  "nome",
  "cognome",
  "codiceFiscale",
  "comune",
  "indirizzo",
  "civico",
  "cap",
  "categoriaSpeciale",
];

const campiRegAzienda = ["email", "password", "ripetiPassword", "ragioneSociale", "partitaIva", "codiceFiscale", "indirizzo", "comune", "settore", "associato"];

export function verificaEmailUtenteRegistrazione(email, setVerificaMail) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (emailRegex.test(email)) {
    fetch(process.env.REACT_APP_URL + "/user/verificaEmailPersonaRegistrazione/" + email, {
      method: "GET",
    })
      .then((res) => {
        if (res.status === 200) {
          setVerificaMail("ok");
        } else {
          setVerificaMail("forbidden");
        }
      })
      .catch((err) => console.error("Si è verificato un errore"));
  } else {
    setVerificaMail("invalid");
  }
}

export function verificaCodiceFiscaleRegistrazione(codiceFiscale, setVerificaCodiceFiscale) {
  let cf = codiceFiscale.toUpperCase();
  let cfReg = /^[A-Z]{6}\d{2}[A-Z]\d{2}[A-Z]\d{3}[A-Z]$/;
  if (!cfReg.test(cf)) {
    setVerificaCodiceFiscale("invalid");
    return;
  }

  let set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX";
  let s = 0;

  for (var i = 1; i <= 13; i += 2) s += setpari.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
  for (i = 0; i <= 14; i += 2) s += setdisp.indexOf(set2.charAt(set1.indexOf(cf.charAt(i))));
  if (s % 26 !== cf.charCodeAt(15) - "A".charCodeAt(0)) {
    setVerificaCodiceFiscale("invalid");
    return;
  }

  fetch(process.env.REACT_APP_URL + "/user/checkCodiceFiscalePersona/" + codiceFiscale, {
    method: "GET",
  }).then((res) => {
    if (res.status === 200) {
      setVerificaCodiceFiscale("ok");
    } else {
      setVerificaCodiceFiscale("invalid");
    }
  });
}

export function verificaPasswordRegistrazione(password, setVerificaPassword) {
  // Define your criteria for a valid password
  const minLength = 8;
  const minUpperCase = 1;
  const minLowerCase = 1;
  const minSpecialChars = 1;
  const specialChars = "!@$%&?:.";

  // Check length
  if (password.length < minLength) {
    setVerificaPassword("Password deve essere lunga almeno " + minLength + " caratteri");
    return;
  }

  // Check uppercase, lowercase, digits, and special characters
  let upperCaseCount = 0;
  let lowerCaseCount = 0;
  let digitCount = 0;
  let specialCharCount = 0;

  for (let i = 0; i < password.length; i++) {
    const char = password.charAt(i);
    if (char.match(/[A-Z]/)) {
      upperCaseCount++;
    } else if (char.match(/[a-z]/)) {
      lowerCaseCount++;
    } else if (char.match(/[0-9]/)) {
      digitCount++;
    } else if (specialChars.includes(char)) {
      specialCharCount++;
    }
  }

  // Check if all criteria are met
  if (upperCaseCount < minUpperCase) {
    setVerificaPassword("Password deve contenere almeno " + minUpperCase + " lettera maiuscole");
    return;
  } else if (lowerCaseCount < minLowerCase) {
    setVerificaPassword("Password deve contenere almeno " + minLowerCase + " lettera minuscola");
    return;
  } else if (specialCharCount < minSpecialChars) {
    setVerificaPassword("Password deve contenere almeno " + minSpecialChars + " carattere speciale: " + specialChars);
    return;
  }

  // If all criteria are met, return null (indicating success)
  setVerificaPassword("ok");
}

export async function completaRegistrazioneAzienda(formData, onRegistrazioneCompletata, setLoading) {
  setLoading(true);

  let formDataCopy = { ...formData };

  //Trasformo l'oggetto comune in comune DTO
  let comune = formDataCopy.comune;
  formDataCopy.comune = {
    des: comune.label,
    codiceComune: comune.value,
    prov: comune.prov,
  };
  //Trasformo l'oggetto settore in attgrp DTO
  let settore = formDataCopy.settore;
  formDataCopy.settore = {
    descrizione: settore.label,
    codice: settore.value,
  };

  const params = new URLSearchParams({
    linkAmbiente: process.env.REACT_APP_URL_FE,
    ambiente: process.env.REACT_APP_AMBIENTE,
  });

  await fetch(process.env.REACT_APP_URL + "/user/completaRegistrazioneAzienda?" + params, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(formDataCopy),
  })
    .then((response) => {
      if (response.ok) {
        setLoading(false);
        onRegistrazioneCompletata(formDataCopy);
        return null;
      } else {
        setLoading(false);
        return response.text();
      }
    })
    .then((messaggio) => {
      if (messaggio) {
        Toast.fire({ icon: "error", title: messaggio });
      }
    });
}

export function completaRegistrazionePersona(formData, onRegistrazioneCompletata, setLoading) {
  setLoading(true);

  let formDataCopy = { ...formData };

  let comune = formDataCopy.comune;
  formDataCopy.comune = {
    des: comune.label,
    codiceComune: comune.value,
    prov: comune.prov,
  };

  const params = new URLSearchParams({
    linkAmbiente: process.env.REACT_APP_URL_FE,
    ambiente: process.env.REACT_APP_AMBIENTE,
  });

  fetch(process.env.REACT_APP_URL + "/user/completaRegistrazionePersona?" + params, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(formDataCopy),
  }).then((res) => {
    if (res.status === 200) {
      onRegistrazioneCompletata(formDataCopy);
    } else {
      onRegistrazioneCompletata("error");
    }

    setLoading(false);
  });
}

export function verificaCampiRegistrazione(tipoRegistrazione, formData, setDataError) {
  let erroreCampi = [];
  let keysReg = null;
  if (tipoRegistrazione === "persona") {
    keysReg = Object.keys(formData);
    campiRegPersona.forEach((key) => {
      if (!keysReg.includes(key) || formData[key] === null || (typeof formData[key] === "string" && formData[key].trim() === "")) {
        erroreCampi.push(key);
      }
    });
  } else if (tipoRegistrazione === "azienda") {
    keysReg = Object.keys(formData);
    campiRegAzienda.forEach((key) => {
      if (!keysReg.includes(key) || formData[key] === null || (typeof formData[key] === "string" && formData[key].trim() === "")) {
        erroreCampi.push(key);
      }

      if (key === "partitaIva" && formData[key]) {
        if (formData[key].length !== 11) {
          erroreCampi.push(key);
        }
      }

      if (key === "codiceFiscale" && formData[key]) {
        if (formData[key].length === 16 || formData[key].length === 11) {
        } else {
          erroreCampi.push(key);
        }
      }

      if (key === "associato") {
        if (formData[key] === "si" && !formData.provConfartigianato) {
          erroreCampi.push("provConfartigianato");
        }
      }
    });
  }
  if (!formData["password"] || !formData["ripetiPassword"] || formData["password"] !== formData["ripetiPassword"]) {
    erroreCampi.push("password");
    erroreCampi.push("ripetiPassword");
  }
  setDataError(erroreCampi);

  return erroreCampi.length === 0;
}

export function verificaAziendaUtenteMailRegistrazione(email, setVerificaEmail) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (emailRegex.test(email) && email) {
    fetch(process.env.REACT_APP_URL + "/user/verificaAziendaUtenteMailRegistrazione/" + email, {
      method: "GET",
    })
      .then((res) => {
        if (res.status === 200) {
          setVerificaEmail("ok");
        } else {
          setVerificaEmail("forbidden");
        }
      })
      .catch((err) => console.error("Si è verificato un errore"));
  } else {
    setVerificaEmail("invalid");
  }
}

export function verificaAziendaUtentePivaRegistrazione(piva, setVerificaPiva) {
  if (piva) {
    fetch(process.env.REACT_APP_URL + "/user/verificaAziendaUtentePivaRegistrazione/" + piva, {
      method: "GET",
    })
      .then((res) => {
        if (res.status === 200) {
          setVerificaPiva("ok");
        } else {
          setVerificaPiva("forbidden");
        }
      })
      .catch((err) => console.error("Si è verificato un errore"));
  } else {
    setVerificaPiva("invalid");
  }
}

function encodeBase64(data) {
  return Buffer.from(data).toString("base64");
}

export async function loginPersona(username, password, onUserLogin) {
  return new Promise((resolve, reject) => {
    let credenziali = username + ":" + password;
    credenziali = encodeBase64(credenziali);

    if (!!username && !!password) {
      fetch(process.env.REACT_APP_URL + "/user/login_persone/" + username, {
        method: "GET",
        headers: {
          Authorization: "Basic " + credenziali,
          From: "persone",
        },
      }).then((res) => {
        if (res.status === 200) {
          localStorage.setItem("accessToken", res.headers.get("Authorization"));
          resolve(res.json());
        } else {
          reject(res);
        }
      });
    }
  });
}

export async function loginAzienda(username, password, onUserLogin) {
  return new Promise((resolve, reject) => {
    let credenziali = username + ":" + password;
    credenziali = encodeBase64(credenziali);

    if (!!username && !!password) {
      fetch(process.env.REACT_APP_URL + "/user/login_azienda/" + username, {
        method: "GET",
        headers: {
          Authorization: "Basic " + credenziali,
          From: "aziende",
        },
      }).then((res) => {
        if (res.status === 200) {
          localStorage.setItem("accessToken", res.headers.get("Authorization"));
          resolve(res.json());
        } else {
          reject(res);
        }
      });
    }
  });
}

export function passwordDimenticataAzienda(email, piva, setLoading) {
  if (piva && email) {
    const params = new URLSearchParams({
      linkAmbiente: process.env.REACT_APP_URL_FE,
      ambiente: process.env.REACT_APP_AMBIENTE,
      piva: piva,
      email: email,
    });

    setLoading(true);
    fetch(process.env.REACT_APP_URL + "/user/passwordDimenticataAzienda?" + params, {
      method: "GET",
    }).then((res) => {
      if (res.status === 200) {
        setLoading(false);
        Toast.fire({ icon: "success", title: "Controlla la tua casella di posta elettronica!" });
      } else {
        setLoading(false);
        Toast.fire({ icon: "error", title: "Si è verificato un errore" });
      }
    });
  }
}

export function passwordDimenticataPersona(email, cf, setLoading) {
  if (cf && email) {
    setLoading(true);

    const params = new URLSearchParams({
      cf: cf,
      email: email,
      linkAmbiente: process.env.REACT_APP_URL_FE,
      ambiente: process.env.REACT_APP_AMBIENTE,
    });

    fetch(process.env.REACT_APP_URL + "/user/passwordDimenticataPersona?" + params, {
      method: "GET",
    }).then((res) => {
      if (res.status === 200) {
        setLoading(false);
        Toast.fire({ icon: "success", title: "Controlla la tua casella di posta elettronica!" });
      } else {
        setLoading(false);
        Toast.fire({ icon: "error", title: "Si è verificato un errore" });
      }
    });
  }
}

export async function getUserInfoPersone() {
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_URL + "/user/getUserInfoPersone", {
      headers: getAuthHeader(),
    })
      .then((res) => {
        if (!res.ok) {
          Toast.fire({ icon: "error", title: "Si è verificato un errore nel caricamento" });
          return null;
        }
        return res.json();
      })
      .then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error("Si è verificato un errore");
        }
      );
  });
}

export async function getUserInfoAziende() {
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_URL + "/user/getUserInfoAziende", {
      headers: getAuthHeader(),
    })
      .then((res) => {
        if (!res.ok) {
          Toast.fire({ icon: "error", title: "Si è verificato un errore nel caricamento" });
          return null;
        }
        return res.json();
      })
      .then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error("Si è verificato un errore");
        }
      );
  });
}

export async function getUserInfoConfartigianato() {
  return new Promise((resolve, reject) => {
    fetch(process.env.REACT_APP_URL + "/user/getUserInfoConfartigianato", {
      headers: getAuthHeader(),
    })
      .then((res) => {
        if (!res.ok) {
          Toast.fire({ icon: "error", title: "Si è verificato un errore nel caricamento" });
          return null;
        }
        return res.json();
      })
      .then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.error("Si è verificato un errore");
        }
      );
  });
}

export function verificaPasswordDimenticata(password, errori, setErrori, field) {
  // Define your criteria for a valid password
  const minLength = 8;
  const minUpperCase = 1;
  const minLowerCase = 1;
  const minSpecialChars = 1;
  const specialChars = "!@#$%^&*()_+{}:<>?";

  // Check length
  if (password.length < minLength) {
    setErrori({ ...errori, [field]: field + ": Password deve essere lunga almeno " + minLength + " caratteri" });
    return;
  }

  // Check uppercase, lowercase, digits, and special characters
  let upperCaseCount = 0;
  let lowerCaseCount = 0;
  let digitCount = 0;
  let specialCharCount = 0;

  for (let i = 0; i < password.length; i++) {
    const char = password.charAt(i);
    if (char.match(/[A-Z]/)) {
      upperCaseCount++;
    } else if (char.match(/[a-z]/)) {
      lowerCaseCount++;
    } else if (char.match(/[0-9]/)) {
      digitCount++;
    } else if (specialChars.includes(char)) {
      specialCharCount++;
    }
  }

  // Check if all criteria are met
  if (upperCaseCount < minUpperCase) {
    setErrori({ ...errori, [field]: field + ": Password deve contenere almeno " + minUpperCase + " lettera maiuscole" });
    return;
  } else if (lowerCaseCount < minLowerCase) {
    setErrori({ ...errori, [field]: field + ": Password deve contenere almeno " + minLowerCase + " lettera minuscola" });
    return;
  } else if (specialCharCount < minSpecialChars) {
    setErrori({ ...errori, [field]: field + ": Password deve contenere almeno " + minSpecialChars + " carattere speciale" });
    return;
  }

  // If all criteria are met, return null (indicating success)
  setErrori({ ...errori, [field]: "ok" });
}

export function reimpostaPassword(param, password, navigate) {
  param.password = password;

  fetch(process.env.REACT_APP_URL + "/user/cambioPassword", {
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(param),
  }).then(
    (res) => {
      if (res.status === 200) {
        Toast.fire({ icon: "success", title: "Password reimpostata!" });
        navigate("/");
      } else {
        Toast.fire({ icon: "error", title: "Utente non presente o disattivato" });
      }
    },
    (error) => {
      console.error("Si è verificato un errore");
      Toast.fire({ icon: "error", title: "Si è verificato un errore" });
    }
  );
}

export function parseJwt(token) {
  try {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error("Invalid token", error);
    return null;
  }
}

export function isTokenExpired(token) {
  const decodedToken = parseJwt(token);
  if (decodedToken?.exp) {
    const currentTime = Date.now() / 1000; // Current time in seconds
    return decodedToken.exp < currentTime; // true if expired
  }
  return false;
}
