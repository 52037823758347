import { getAuthHeader } from "assets/utils/auth-services";
import { Toast } from "components/Toast";

export function getAziendeConfartigianato(setAziende, filiale, setPending) {
  setPending(true);

  let headers = getAuthHeader();
  headers["Content-Type"] = "application/json";

  fetch(process.env.REACT_APP_URL + "/confartigianato/getAziende/" + filiale, {
    headers: headers,
    method: "GET",
  })
    .then((res) => {
      if (!res.ok) {
        Toast.fire({ icon: "error", title: "Si è verificato un errore nel caricamento" });
        return null;
      }
      return res.json();
    })
    .then((result) => {
      let array = [];
      let aziendeFattePiva = [];

      for (const element of result) {
        if (!aziendeFattePiva.includes(element.partitaIva)) {
          let filter = null;

          if (element.tipoRicerca && element.partitaIva !== null) {
            if (element.tipoRicerca === "attiva") {
              filter = result.find((obj2) => obj2.partitaIva === element.partitaIva && obj2.tipoRicerca === "passiva");

              element.ricerchePassive = filter ? filter.countAnnunci : 0;
              element.ricercheAttive = element.countAnnunci;
            } else if (element.tipoRicerca === "passiva") {
              filter = result.find((obj2) => obj2.partitaIva === element.partitaIva && obj2.tipoRicerca === "attiva");

              element.ricercheAttive = filter ? filter.countAnnunci : 0;
              element.ricerchePassive = element.countAnnunci;
            }
          } else {
            element.ricerchePassive = 0;
            element.ricercheAttive = 0;
          }

          array.push(element);
          aziendeFattePiva.push(element.partitaIva);
        }
      }

      setAziende(array);
      setPending(false);
    })
    .catch((err) => {
      console.error("Si è verificato un errore nel caricamento delle aziende");
      Toast.fire({
        icon: "error",
        title: "Si è verificato un errore nel caricamento delle aziende",
      });
      setPending(false);
    });
}
