import React from "react";
import { Grid, Card, CardContent, Divider, List, ListSubheader, IconButton, Avatar, Tooltip } from "@mui/material";
import { ReactComponent as LOGO_CONFARTIGIANATO } from "../../svgicon/JOB_LOGO_CONFER_BLUE.svg";
import HandshakeIcon from "@mui/icons-material/Handshake";
import PersonIcon from "@mui/icons-material/Person";
import FactoryIcon from "@mui/icons-material/Factory";
import HomeIcon from "@mui/icons-material/Home";
import coloriVetrina from "../../json/coloriVetrina.json";
import { useLocation, useNavigate } from "react-router-dom";
import JobErItemMenu from "components/JobErItemMenu";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { useJobErController } from "context";
import AppBarPersone from "./AppBarPersone";

function LayoutPersone(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [controller] = useJobErController();
  const { authUser } = controller;

  return (
    <>
      <Grid
        container
        style={{
          minWidth: "100%",
          height: "fit-content",
        }}
        sx={{
          display: { xs: "none", md: "flex" },
          backgroundColor: "#002b48!important",
        }}
      >
        <Grid
          item
          xs={2}
          style={{
            padding: 12,
          }}
        >
          <Card sx={{ borderRadius: 7, height: "98vh" }}>
            <CardContent sx={{ paddingTop: 0 }}>
              <Grid container>
                <Grid item xs={12}>
                  {process.env.REACT_APP_AMBIENTE === "modena-reggio" ? (
                    <img
                      alt="logo_My_Lapam_Job"
                      src={require("../../immagini/MyLapamJOB_Logo.png")}
                      style={{
                        height: "70px",
                        width: "250px",
                        borderRadius: 15,
                      }}
                    />
                  ) : (
                    <LOGO_CONFARTIGIANATO className="MuiSvgIcon-root MuiSvgIcon-fontSizeLarge iconBB_lg"></LOGO_CONFARTIGIANATO>
                  )}
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <List
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                      <ListSubheader component="div" id="nested-list-subheader" style={{ fontWeight: "bold" }}>
                        Menu
                      </ListSubheader>
                    }
                  >
                    <JobErItemMenu
                      title="Home"
                      active={location.pathname === "/Persone/Home"}
                      icon={
                        <HomeIcon
                          sx={{
                            color: location.pathname === "/Persone/Home" ? coloriVetrina.blue : coloriVetrina.gray,
                          }}
                        />
                      }
                      onClick={() => navigate("/Persone/Home")}
                    />
                    <JobErItemMenu
                      title="Annunci"
                      active={location.pathname === "/Persone/Annunci"}
                      icon={
                        <FactoryIcon
                          sx={{
                            color: location.pathname === "/Persone/Annunci" ? coloriVetrina.blue : coloriVetrina.gray,
                          }}
                        />
                      }
                      onClick={() => navigate("/Persone/Annunci")}
                    />
                    <JobErItemMenu
                      title="Candidature"
                      active={location.pathname === "/Persone/Candidature"}
                      icon={
                        <HandshakeIcon
                          sx={{
                            color: location.pathname === "/Persone/Candidature" ? coloriVetrina.blue : coloriVetrina.gray,
                          }}
                        />
                      }
                      onClick={() => navigate("/Persone/Candidature")}
                    />
                    <JobErItemMenu
                      title="Profilo"
                      active={location.pathname === "/Persone/Profilo"}
                      icon={
                        <PersonIcon
                          sx={{
                            color: location.pathname === "/Persone/Profilo" ? coloriVetrina.blue : coloriVetrina.gray,
                          }}
                        />
                      }
                      onClick={() => navigate("/Persone/Profilo")}
                    />
                  </List>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={10} style={{ padding: 12 }}>
          <Grid container justifyContent="flex-end" pr={2} mb={3} textAlign="right" spacing={1} alignSelf="center">
            {/* <Grid
              item
              xs="auto"
              style={{ textAlign: "right!important" }}
              alignSelf="center"
            >
              <IconButton aria-label="delete">
                <NotificationsIcon
                  sx={{ fontSize: 32, color: coloriVetrina.warning }}
                />
              </IconButton>
            </Grid> */}
            <Grid item xs="auto" style={{ textAlign: "right!important", alignSelf: "center" }}>
              <Tooltip title="Logout">
                <IconButton
                  onClick={() => {
                    localStorage.removeItem("accessToken");
                    navigate("/Vetrina/Home");
                  }}
                >
                  <PowerSettingsNewIcon sx={{ fontSize: 40, color: coloriVetrina.error }} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs="auto" style={{ textAlign: "right!important", alignSelf: "center" }}>
              <Grid container spacing={1}>
                <Grid item xs="auto">
                  {authUser?.nome && authUser?.cognome && (
                    <Tooltip title={authUser.cognome + " " + authUser.nome}>
                      <Avatar
                        onClick={() => {
                          navigate("/Persone/Profilo");
                        }}
                        sx={{ width: 40, height: 40 }}
                      >
                        {authUser.cognome.substring(0, 2)}
                      </Avatar>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {props.child}
        </Grid>
      </Grid>
      <Grid
        container
        style={{
          minWidth: "100%",
          height: "fit-content",
        }}
        sx={{
          display: { xs: "block", md: "none" },
          backgroundColor: coloriVetrina.bgPortale + "!important",
        }}
      >
        <AppBarPersone />
        <Grid container>{props.child}</Grid>
      </Grid>
    </>
  );
}
export default LayoutPersone;
